export const MenuList = [
  // Dashboard
  {
    title: 'Reports',
    classChange: 'mm-collapse',
    iconStyle: 'bi bi-grid',
    content: [
      {
        title: 'Nominations',
        to: 'nomination-reports',
      },
      {
        title: 'Short-lis1ted Candidates',
        to: 'short-listed-candidates',
      },
      {
        title: 'Corresponding Membership and Special Election',
        to: 'corresponding-and-special-elections',
      },
    ],
    access: ['super-admin', 'admin'],
  },

  // Nominations
  {
    title: 'Nominations',
    iconStyle: 'bi bi-list',
    to: 'nominations',
    access: ['fellow', 'super-admin'],
  },
  // Filtering table
  {
    title: 'My References',
    iconStyle: 'bi bi-journals',
    to: 'referee',
    access: ['referee', 'super-admin'],
  },

  // Admin menu items
  {
    title: 'Nomination - Admin',
    iconStyle: 'bi bi-list-stars',
    to: 'nomination-administration',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Referee - Admin',
    iconStyle: 'bi bi-journal-medical',
    to: 'referee-administration',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Shortlisting',
    iconStyle: 'bi bi-star-fill',
    to: 'nomination-shortlisting',
    access: ['admin', 'committee-chair', 'committee-member', 'super-admin'],
  },
  {
    title: 'Assessor',
    iconStyle: 'bi bi-person-plus-fill',
    to: 'assessor-administration',
    access: ['admin', 'committee-chair', 'super-admin'],
  },
  {
    title: 'Elect Candidates',
    iconStyle: 'bi bi-check',
    to: 'elect-candidate',
    access: ['admin', 'super-admin'],
  },

  // Secretary Menu
  {
    title: 'Nomination - Secretary',
    iconStyle: 'bi bi-list-ol',
    to: 'nomination-secretary',
    access: ['secretary'],
  },
  {
    title: 'Referee - Secretary',
    iconStyle: 'bi bi-journal-text',
    to: 'referee-report-secretary',
    access: ['secretary'],
  },

  // Filtering table
  {
    title: 'Administration',
    classChange: 'mm-collapse',
    iconStyle: 'bi bi-lock',
    content: [
      {
        title: 'Extension Requests',
        to: 'referee-report-extension-requests',
      },
      {
        title: 'Committees',
        to: 'committees',
      },
      {
        title: 'Admin Actions',
        to: 'admin-actions',
      },
    ],
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Secretary Actions',
    iconStyle: 'bi bi-download',
    to: 'secretary-actions',
    access: ['secretary'],
  },
];
