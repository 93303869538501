export const formatIndependentAssessorData = (data) => {
  const date = new Date();

  data.refereeNine = Number.isNaN(parseInt(data.refereeNine))
    ? null
    : parseInt(data.refereeNine);
  data.refereeEight = Number.isNaN(parseInt(data.refereeEight))
    ? null
    : parseInt(data.refereeEight);

  data.refereeSevenTypeId =
    typeof data?.refereeSeven === 'number' ? data.refereeSeven : null;

  data.refereeEightTypeId =
    typeof data?.refereeEight === 'number' ? data.refereeEight : null;
  data.refereeNineTypeId =
    typeof data?.refereeNine === 'number' ? data.refereeNine : null;

  const refereeIdentifiers = ['Seven', 'Eight', 'Nine'];

  refereeIdentifiers.forEach((identifier) => {
    const refereeTypeId = data[`referee${identifier}TypeId`] ?? null;
    const nonAasRefereeId = data[`nonAasReferee${identifier}Id`] ?? null;

    if (
      typeof refereeTypeId === 'number' &&
      refereeTypeId === 0 &&
      typeof nonAasRefereeId === 'object' &&
      nonAasRefereeId?.value === 0
    ) {
      data[`newNonAasReferee${identifier}`] = {
        titleId: data[`referee${identifier}TitleId`],
        name: data[`referee${identifier}Name`],
        lastName: data[`referee${identifier}LastName`],
        email: data[`referee${identifier}Email`],
        countryId: data[`referee${identifier}CountryId`].value,
        organisation:
          data[`referee${identifier}Organisation`] &&
          data[`referee${identifier}Organisation`] !== ''
            ? data[`referee${identifier}Organisation`]
            : data[`referee${identifier}EmailEducationalInstitution`],
        memberships: data[`referee${identifier}Memberships`],
        label: `${data[`referee${identifier}Name`]} ${data[`referee${identifier}LastName`]} (${data[`referee${identifier}Email`]})`,
        publishedAt: date.toISOString().split('T')[0],
      };
    }
  });

  const assignRefereeId = (
    dataObject,
    refereeIdKey,
    refereeTypeIdKey,
    expectedTypeId,
  ) => {
    data[refereeIdKey] =
      data?.[refereeIdKey]?.value && data[refereeTypeIdKey] === expectedTypeId
        ? data[refereeIdKey].value
        : null;
  };

  assignRefereeId(data, 'refereeSevenFellowId', 'refereeSevenTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeSevenId', 'refereeSevenTypeId', 0);
  assignRefereeId(data, 'refereeEightFellowId', 'refereeEightTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeEightId', 'refereeEightTypeId', 0);
  assignRefereeId(data, 'refereeNineFellowId', 'refereeNineTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeNineId', 'refereeNineTypeId', 0);

  // PUBLICATION DATE
  [data.publishedAt] = date.toISOString().split('T');

  return data;
};
