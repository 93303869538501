export const addCmseCommitteeToSectionalCommitteeArray = (
  sectionalCommitteeArray,
) => [
  ...sectionalCommitteeArray,
  {
    id: 14,
    attributes: {
      name: 'Corresponding Membership',
    },
  },
  {
    id: 15,
    attributes: {
      name: 'Special Election',
    },
  },
];
