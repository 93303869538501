import html2pdf from 'html2pdf.js';

const camelCaseToTitle = (camelCaseStr) =>
  camelCaseStr
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^\w/, (c) => c.toUpperCase());

const getTextWidth = (text, fontSize) => {
  const averageCharWidth = fontSize * 0.5;
  return text.length * averageCharWidth;
};

const numberWords = ['One', 'Two', 'Three', 'Four', 'Five', 'Six']; // Array of number words

export const handleDownload = async (candidate, pdfUrls, refereeReportUrls) => {
  const elements = document.querySelectorAll('.card');

  if (refereeReportUrls) {
    refereeReportUrls.forEach((referee, index) => {
      referee.name = `referee${numberWords[index]}`; // Use number words for naming
    });
  }

  if (elements.length === 0) {
    console.error('No elements with class "card" found.');
    return;
  }

  // format the element before converting to pdf
  const clonedElements = Array.from(elements).map((element) => {
    const clonedElement = element.cloneNode(true);

    // Remove card title
    const h4Element = clonedElement.querySelector('h4.card-title');
    if (h4Element && h4Element.textContent.trim() === 'Nomination View') {
      h4Element.remove();
    }

    // Remove all inline styles
    clonedElement.removeAttribute('style');

    // Remove inline styles from all child elements
    const allChildElements = clonedElement.querySelectorAll('*');
    allChildElements.forEach((child) => child.removeAttribute('style'));

    // Remove all classes to remove class-based styling
    clonedElement.className = '';
    allChildElements.forEach((child) => (child.className = ''));

    // Remove buttons
    const buttons = clonedElement.querySelectorAll('button');
    buttons.forEach((button) => button.remove());

    // Make all span text bold to differentiate labels from field value
    const spans = clonedElement.querySelectorAll('span');
    spans.forEach((span) => {
      span.style.fontWeight = 'bold';
    });

    return clonedElement;
  });

  const mergedPdfsArray = [...pdfUrls, ...(refereeReportUrls || [])];

  const htmlContent = Array.from(clonedElements)
    .map((clonedElement) => clonedElement.outerHTML)
    .join('<br><br>');

  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  tempElement.style.fontSize = '12px';
  tempElement.style.color = 'black';
  tempElement.style.margin = '0';
  tempElement.style.padding = '20px';
  document.body.appendChild(tempElement);

  try {
    const options = {
      margin: 20,
      filename: `${candidate}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const pdf = html2pdf().from(tempElement).set(options).outputPdf('blob');

    const additionalPdfPromises = mergedPdfsArray.map((pdfUrl) =>
      fetch(pdfUrl.path).then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to fetch additional PDF: ${response.statusText}`,
          );
        }
        return response.arrayBuffer();
      }),
    );

    const additionalPdfArrayBuffers = await Promise.all(additionalPdfPromises);

    const pdfBlob = await pdf;
    const pdfBytes = await pdfBlob.arrayBuffer();

    const { PDFDocument, rgb } = await import('pdf-lib');
    const [generatedPdfDoc, ...additionalPdfDocs] = await Promise.all([
      PDFDocument.load(pdfBytes, { ignoreEncryption: true }),
      ...additionalPdfArrayBuffers.map((buffer) =>
        PDFDocument.load(buffer, { ignoreEncryption: true }),
      ),
    ]);

    const newPdfDoc = await PDFDocument.create();

    const frontPage = newPdfDoc.addPage([595.28, 841.89]);
    const title = 'Nomination Details';
    const titleWidth = getTextWidth(title, 24);

    frontPage.drawText(title, {
      x: (frontPage.getWidth() - titleWidth) / 2,
      y: 400,
      size: 24,
      color: rgb(0, 0, 0),
    });

    // Add the generated pages
    const generatedPages = await newPdfDoc.copyPages(
      generatedPdfDoc,
      generatedPdfDoc.getPageIndices(),
    );
    generatedPages.forEach((page) => newPdfDoc.addPage(page));

    let blankPageNumber = 1;

    for (const additionalPdfDoc of additionalPdfDocs) {
      const blankPage = newPdfDoc.addPage([595.28, 841.89]);
      const blankPageIndex = newPdfDoc.getPageCount() - 1;
      const blankPageContext = newPdfDoc.getPage(blankPageIndex);
      const publicationName = camelCaseToTitle(
        mergedPdfsArray[blankPageNumber - 1].name,
      );
      const textWidth = getTextWidth(publicationName, 24);

      blankPageContext.drawText(publicationName, {
        x: (blankPage.getWidth() - textWidth) / 2,
        y: 400,
        size: 24,
        color: rgb(0, 0, 0),
      });

      blankPageNumber += 1;

      const additionalPages = await newPdfDoc.copyPages(
        additionalPdfDoc,
        additionalPdfDoc.getPageIndices(),
      );
      additionalPages.forEach((page) => newPdfDoc.addPage(page));
    }

    const newPdfBytes = await newPdfDoc.save();
    const newPdfBlob = new Blob([newPdfBytes], { type: 'application/pdf' });
    const newPdfUrl = URL.createObjectURL(newPdfBlob);

    const link = document.createElement('a');
    link.href = newPdfUrl;
    link.download = `${candidate}.pdf`;
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error generating PDF:', error);
    alert(
      'Download unsuccessful. Please reach out to our support team at ict@science.org.au for assistance in resolving the issue.',
    );
  } finally {
    document.body.removeChild(tempElement);
  }
};
