import React, { useEffect, useState, useContext } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { axiosPut } from '../../utils/axiosPut';
import { windowScrollToTop } from '../../utils/windowScrollToTop';

import PageTitle from '../layouts/PageTitle';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { CommonCollections } from '../../App';

const ChairCommittee = () => {
  const { activeFellowsArray, sectionalCommittees, usersArray } =
    useStaticCollections();
  const { chairCommitteeArray } = useAdminCollections();
  const [notification, setNotification] = useState(null);
  const { control, setValue, handleSubmit } = useForm();
  const { setIsFormSubmitted } = useContext(CommonCollections);

  const updatedSectionalCommittees = {
    ...sectionalCommittees,
    14: 'CMSE: Corresponding Member and Special Election Committee',
  };
  useEffect(() => {
    (async () => {
      try {
        chairCommitteeArray.forEach(({ id, attributes }) => {
          if (attributes.committeeChair.data) {
            const selectedFellow = activeFellowsArray.find(
              (fellow) =>
                fellow.id ===
                parseInt(attributes.committeeChair?.data?.attributes.fellowId),
            );
            if (selectedFellow) {
              setValue(`committee-chair-${id}`, {
                value: attributes.committeeChair.data.id,
                label: selectedFellow.attributes.label,
              });
            }
          }

          const committeeMembers = [];
          attributes.committeeMembers.data.forEach((cm) => {
            const selectedFellow = activeFellowsArray.find(
              (fellow) => fellow.id === parseInt(cm.attributes.fellowId),
            );
            if (selectedFellow) {
              const committeeMember = {
                value: cm.id,
                label: selectedFellow.attributes.label,
              };
              committeeMembers.push(committeeMember);
            }
            if (cm.attributes.nonAasRefereeId !== null) {
              const committeeMember = {
                value: cm.id,
                label: cm.attributes.username,
              };
              committeeMembers.push(committeeMember);
            }
          });

          setValue(`committee-member-${id}`, committeeMembers);
        });
      } catch (error) {
        console.error('Error in ChairCommittee:', error);
      }
    })();
  }, [chairCommitteeArray, setValue, activeFellowsArray]);

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    try {
      for (const [id, selectedOption] of Object.entries(formData)) {
        const rowId = id.match(/\d+/)[0];
        if (id.includes('committee-chair')) {
          if (selectedOption) {
            await axiosPut(`update-committee/${rowId}`, {
              data: {
                committeeChair: { id: selectedOption.value },
              },
            });
          }
        }
        if (id.includes('committee-member')) {
          if (selectedOption) {
            const userIds = selectedOption.map((option) => ({
              id: option.value,
            }));

            await axiosPut(`update-committee/${rowId}`, {
              data: {
                committeeMembers: userIds,
              },
            });
          }
        }
        setIsFormSubmitted(true);
      }
      setNotification('Updated');
      setTimeout(() => {
        setNotification('');
      }, 5000);
      windowScrollToTop();
    } catch (error) {
      console.error('Error updating emails:', error);
    }
  };

  const selectOptions = activeFellowsArray.map((item) => {
    if (item.id) {
      const user = usersArray.find((users) => users.fellowId === item.id);
      if (user) {
        return {
          value: user.id, // Replace value with user id
          label: item.attributes.label,
        };
      }
    }
    return null;
  });

  return (
    <>
      <PageTitle
        activeMenu="Committees"
        motherMenu="Home"
        notificationMessage={notification}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Committees</h4>
        </div>
        <div className="card-body text-left">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {Object.entries(updatedSectionalCommittees).map(([id, label]) => (
                <React.Fragment key={`committee-${id}`}>
                  <Col key={`chair-${id}`} xs={12} md={6}>
                    <div className="mb-xl-5">
                      <label
                        htmlFor={`committee-chair-${id}`}
                        className="form-label"
                      >
                        {`${label} - Chair`}
                      </label>
                      <Controller
                        name={`committee-chair-${id}`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={selectOptions}
                            onChange={(selectedOption) => {
                              setValue(`committee-chair-${id}`, selectedOption);
                            }}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                height: `3rem`,
                                borderRadius: '0.5rem',
                                borderColor: '#B3B3B3',
                              }),
                            }}
                            className="mt-1"
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col key={`member-${id}`} xs={12} md={6}>
                    <div className="mb-xl-5">
                      <label
                        htmlFor={`committee-member-${id}`}
                        className="form-label"
                      >
                        {`${label} - Committee Member`}
                      </label>
                      <Controller
                        name={`committee-member-${id}`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={selectOptions}
                            onChange={(selectedOption) => {
                              setValue(
                                `committee-member-${id}`,
                                selectedOption,
                              );
                            }}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderRadius: '0.5rem',
                                borderColor: '#B3B3B3',
                              }),
                            }}
                            isMulti
                            className="mt-1"
                          />
                        )}
                      />
                    </div>
                  </Col>
                </React.Fragment>
              ))}
            </div>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChairCommittee;
